
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .viewerMap {
  position: fixed;
  z-index: $Z_INDEX_MODAL;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $HEIGHT_GNB;
    padding: 0 24px;
    background: var(--bg-main);

    span[role="img"] {
      color: var(--icon-default);
    }

    > .left {
      display: flex;
      align-items: center;
      gap: 12px;

      > p {
        @include heading2;
      }
    }
    > .right {
      display: flex;
      gap: 8px;
    }
  }

  .mapContainer {
    width: 100%;
    height: 100%;

    :global(.ol-swipe:before) {
      left: calc(50% + 1px);
      background: var(--static-50);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.15);
    }

    :global(.ol-swipe) {
      height: 100%;
      background: transparent;

      button:before {
        margin-right: 1px;
        content: url("../asset/icons/ArrowBack.png");
      }

      button {
        position: absolute;
        top: 50%;
        left: -28px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: var(--static-50);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
      }

      button:after {
        margin-left: 1px;
        content: url("../asset/icons/ArrowForward.png");
      }

      button:before,
      button:after {
        position: initial;
        width: initial;
        background: transparent;
      }

      button:hover,
      button:focus {
        outline: 1px solid var(--icon-secondary);
      }
    }

    :global(.ol-swipe:after) {
      @include body1-semi-bold;
      top: 0;
      bottom: 0;
      left: 4px;
      width: 120px;
      height: 32px;
      padding: 4px 25px;
      content: "SuperX";
      text-align: center;
      color: var(--white-color);
      background: rgba(0, 0, 0, 0.5);
    }

    :global(.tile-load-progress) {
      position: absolute;
      z-index: 1;
    }
  }
}