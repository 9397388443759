@mixin custom-scroll-bar {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 2px;
    background: #00000026;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-resizer {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    //width: 12px;
    //height: 12px;
  }
  &:hover::-webkit-scrollbar-track {
    background: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    //width: 8px;
    //height: 8px;
    //border-radius: 4px;
    background: #00000040;
  }
}

@mixin mobile-media-query {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin min-tablet-media-query { // To Be tablet
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet-media-query { // To Be laptop
  @media (max-width: 1200px) {
    @content;
  }
}
