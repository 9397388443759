
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .sideNavigationBar {
  display: flex;
  flex-flow: column nowrap;
  width: 254px;

  .logoWrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;

    .logoLink {
      display: flex;
      align-items: center;
      padding: 0;
      gap: 8px;

      .logo {
        display: flex;
        height: 28px;
        object-fit: cover;
      }
      .logoMainText {
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        line-height: 24px; /* 120% */
        color: var(--text-main);
      }
      .logoSubText {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        line-height: 24px; /* 150% */
        color: var(--text-main);
      }
    }
  }

  .profileMenu {
    margin-bottom: 24px;
  }

  a {
    @include body2-medium;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 8px 24px;
    color: var(--text-main);
    border-radius: var(--radius-medium);

    &:not(.logoLink):hover {
      background-color: var(--planet-blue-50);
    }
  }

  .active {
    background-color: var(--planet-blue-50);
  }
}
