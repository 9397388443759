@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
@import "../../style/mixin.scss";

.signUp {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  .content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    width: 480px;
    height: fit-content;

    > .titleContainer {
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
      line-height: 32px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    > form {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      gap: 24px;

      .passwordHelperText {
        margin-bottom: -16px;
      }

      .horizontal {
        display: flex;
        gap: 12px;
      }

      .checkboxGroup {
        display: flex;
        flex-flow: column nowrap;
        gap: 8px;

        .checkbox {
          @include body2-regular;
          padding-left: 4px;

          a {
            @include body2-semi-bold;
            color: var(--comp-primary);
          }
        }
        .checkbox.required {
          :global(.ods-checkbox-icon) {
            border-color: var(--semantic-danger);
          }
        }
      }

      .signUpBtn {
        overflow: hidden;
        width: 100%;
      }

      .checkboxGroup + .signUpBtn {
        margin-top: 8px;
      }
    }

    > .navigateToSignIn {
      @include body1-regular;
      color: var(--grey-600);

      a {
        @include body1-semi-bold;
        color: var(--comp-primary);
      }
    }

    .titleContainer + form {
      margin-top: 42px;
    }
    form + .navigateToSignIn {
      margin-top: 24px;
    }
  }
}

:global(.ods-dropdown) {
  :global(.ods-dropdown-label-text) {
    display: flex;
    height: 20px;
  }
  :global(.ods-dropdown-select) {
    border-color: var(--border-default);

    &:hover {
      border-color: var(--border-active);
    }
  }
}

@include mobile-media-query {
  .signUp {
    .content {
      .desc {
        font-size: 16px;
      }
    }
  }
}

@include tablet-media-query {
  .signUp {
    width: 100%;

    .content {
      width: 100%;
      padding: 0 14px;
      .titleContainer {
        p {
          word-break: keep-all;
        }
      }
    }
  }
}
