@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
@import "../../style/mixin.scss";

.forgotPassword {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 14px;

  .content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    width: 480px;
    height: fit-content;

    > .titleContainer {
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .desc {
      @include body1-regular;
      align-self: flex-start;
      margin: {
        top: 14px;
        left: 12px;
      }
    }

    > form {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      gap: 48px;
      margin-top: 40px;
    }
  }
}

@include mobile-media-query {
  .forgotPassword {
    .content {
      .desc {
        font-size: 16px;
      }
    }
  }
}

@include tablet-media-query {
  .forgotPassword {
    width: 100%;

    .content {
      width: 100%;
    }
  }
}
