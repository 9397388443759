
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          html,
body,
#root {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  @include body2-regular;
  color: var(--text-main);
  background-color: var(--bg-main);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
figure,
dl,
dt,
dd,
time,
section,
aside,
header,
nav,
footer,
main,
div,
ul,
ol,
li,
span,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

li,
ol {
  list-style: none;
}

span[role="img"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ol-zoom {
  position: fixed;
  top: 50%;
  right: $RIGHT_MAP_TOOL;
  left: initial;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;
  width: 44px;
  height: 87px;
  transform: translateY(-50%);
  border: none;
  border-radius: var(--radius-large);
  background: var(--comp-container-100);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

  &:hover {
    border-radius: var(--radius-medium);
  }

  button {
    font-size: 24px;
    font-weight: initial;
    width: 44px;
    height: 44px;
    margin: 0;
    cursor: pointer;
    color: var(--icon-default);
    border: 1px solid var(--border-divider);
    background: var(--comp-container-100);
  }
  button:hover,
  button:focus {
    color: var(--icon-only);
    outline: initial;
    background: var(--comp-hover-grey);
  }
  button + button {
    margin-top: -1px;
  }
}
.ol-zoom-in {
  border-radius: var(--radius-medium) var(--radius-medium) 0 0 !important;
}
.ol-zoom-out {
  border-radius: 0 0 var(--radius-medium) var(--radius-medium) !important;
}

.ol-rotate {
  display: none;
}

.tile-load-progress {
  position: relative;
  visibility: hidden;
}
.tile-load-progress::after {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  animation: move 1s linear infinite;
  border-radius: 100px;
  background-image: linear-gradient(
                  -45deg,
                  rgba(255, 255, 255, 0.2) 25%,
                  transparent 25%,
                  transparent 50%,
                  rgba(255, 255, 255, 0.2) 50%,
                  rgba(255, 255, 255, 0.2) 75%,
                  transparent 75%,
                  transparent
  );

  background-size: 40px 40px;
}
.tile-load-progress.tile-loading {
  visibility: visible;
}
progress[value] {
  width: 100%;
  height: 8px;
  border-radius: 100px;
  background: var(--comp-container-200);
  -webkit-appearance: none;
  appearance: none;
}
progress[value]::-webkit-progress-bar {
  border-radius: 100px;
  background: var(--comp-container-200);
}
progress[value]::-webkit-progress-value {
  border-radius: 100px;
  background-color: var(--comp-primary);
}
@keyframes expand-width {
  0% {
    width: 0;
  }
  100% {
    width: auto;
  }
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 40px;
  }
}
progress[value]::-moz-progress-bar {
  border-radius: 100px;
  background-color: var(--comp-primary);
}