@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";

.emptyStatus {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;
  height: 200px;
  text-align: center;

  span[role="img"] {
    font-size: 64px;
    display: flex;
    color: transparent;
  }

  > .title {
    @include heading3;
    display: flex;
    margin-top: 8px;
    color: var(--grey-600);
  }
  > .desc {
    @include body2-regular;
    display: flex;
    white-space: pre-line;
    color: var(--grey-500);
  }

  .title + .desc {
    margin-top: 4px;
  }
}
