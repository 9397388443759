
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .app {
  @include custom-scroll-bar;
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
