$HEIGHT_GNB: 64px;
$HEIGHT_PANEL_TITLE: 31px;
$HEIGHT_PANEL_PAGINATION: 48px;
$Z_INDEX_GNB: 50;
$Z_INDEX_MAP_TOOL: 75;
$Z_INDEX_EXPANDED_GNB: 80;
$Z_INDEX_POPOVER: 100;
$Z_INDEX_MODAL: 150;
$MAX_WIDTH_PARAGRAPH: 596px;
$WIDTH_SNB: 64px;
$WIDTH_PANEL: 384px;
$WIDTH_MODAL: 616px;
$WIDTH_MODAL_SMALL: 416px;
$WIDTH_FILTER_POPOVER: 288px;
$RIGHT_MAP_TOOL: 24px;
