
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .home {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 56px 40px 84px;

  .sideNavigationBar {
    display: flex;
    margin-right: 40px;

    a:hover {
      color: var(--text-main);
    }
  }
}
