.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  animation: rotation 0.75s infinite linear;
  border-radius: 50%;
  background: conic-gradient(from 0deg at 50% 50%, rgba(72, 122, 255, 0) 0deg, #487aff 360deg);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #fff 0);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #fff 0);

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.loading-small {
  width: 40px;
  height: 40px;
  mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #fff 0);
}

.loading-xs {
  width: 24px;
  height: 24px;
  mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #fff 0);
}
