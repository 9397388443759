
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .tableTool {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .title {
    @include heading1;
    color: var(--text-main);
  }

  .filterContainer {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    max-width: 100%;
    height: 36px;
    gap: 6px;

    > div:nth-child(n) {
      flex-grow: 1;
    }

    > div:first-child {
      flex-grow: 0;
      flex-shrink: 0;
      width: 320px;
    }

    .searchContainer {
      display: flex;
      flex-flow: row nowrap;
      gap: 6px;
    }
  }

  .tableTotalInfo {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    padding: 10px 24px;
    border-radius: var(--radius-medium);
    background-color: var(--comp-container-100);

    > .left {
      @include one-line-ellipsis;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      line-height: 24px;
      display: flex;
      color: var(--text-main);
      gap: 4px;

      > .highlightInfo {
        color: var(--comp-primary);
      }
    }

    > .right {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .title + .filterContainer {
    margin-top: 28px;
  }
  .filterContainer + .tableTotalInfo {
    margin-top: 24px;
  }
}

:global(.ant-menu) {
  @include custom-scroll-bar;
  overflow: auto;
  max-height: 363px;
}