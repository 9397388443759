@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";

.verifyEmail {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;

  .content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    width: fit-content;
    height: fit-content;

    .mailBox {
      width: 80px;
      height: 80px;
    }
    > .title {
      @include heading1;
    }
    > .desc {
      @include body1-regular;
      text-align: center;
      white-space: pre-wrap;
      color: var(--text-sub);

      strong {
        @include body1-semi-bold;
        color: var(--text-main);
      }
      .resendBtn {
        @include body1-semi-bold;
        cursor: pointer;
        color: var(--planet-blue-500);
      }
    }

    .mailBox + .title {
      margin-top: 28px;
    }
    .title + .desc {
      margin-top: 16px;
    }
    .desc + .resendBtn {
      margin-top: 32px;
    }
  }
}
