@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";

.errorPage {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  background-color: var(--planet-blue-50);

  > .title {
    font-size: 68px;
    font-weight: 800;
    font-style: normal;
    line-height: 60px;
    margin-top: 209px;
    letter-spacing: -0.01em;
    color: var(--text-main);
  }

  > .imgWrapper {
    position: relative;
    width: 429px;
    height: 163px;
    margin-top: 95px;

    > .errorEffectImg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  > .desc {
    @include body1-medium;
    margin-top: 201px;
    color: var(--text-main);
  }

  > .backBtn {
    margin-top: 18px;
  }

  > .moonRocketWrapper {
    position: absolute;
    top: 477px;
    left: 46%;
    width: 290px;

    > .blueMoonImg {
      width: 177px;
    }

    > .rocketImg {
      position: absolute;
      top: -65.13px;
      left: -113.8px;
    }
  }
}
