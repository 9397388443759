
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .customPagination {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 1px 20px;
  border-top: 1px solid var(--border-default);
  border-radius: 0 0 8px 8px;
  background: var(--comp-container-100);

  .pageSizer {
    @include caption1-regular;
    display: flex;
    align-items: center;
    gap: 8px;

    :global(.ods-page-size-changer) {
      :global(.ant-select-selector) {
        border-radius: 6px;
      }
      :global(.ant-select-selection-item) {
        @include caption1-regular;
        margin-right: 12px;
      }
    }
  }

  .pagination {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
:global(.ods-page-size-changer-dropdown) {
  :global(.ant-select-item-option-content) {
    @include caption1-regular;
    display: flex;
    align-items: center;
  }
}