@import "../../style/mixin.scss";

.signInLayout {
  display: flex;
  width: 100%;
  height: 100%;

  .leftPanel {
    position: relative;
    display: flex;
    width: 37%;
    height: 100%;
    background: var(--planet-blue-600);

    .heroImage {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 33%;
      width: 82%;
      transform: translateY(-50%);
      object-fit: contain;
    }
  }

  .outletContainer {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: center;
    width: 63%;
    height: 100%;

    .scrollable {
      @include custom-scroll-bar;
      max-width: 100%;
      max-height: 100%;
      margin: 68px 0 84px; // 84 = 20 + 28 + 28
    }
    .ovisionLogo {
      position: absolute;
      bottom: 36px;
      width: 152px;
      height: 24px;
      object-fit: contain;
    }
  }

  .closeBtn {
    position: fixed;
    top: 28px;
    right: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 40px;
      height: 40px;
    }
    span[role="img"] {
      font-size: var(--icon-large);
      color: var(--icon-secondary);
    }
  }
}

@include tablet-media-query {
  .signInLayout {
    .leftPanel {
      display: none;
    }

    .outletContainer {
      width: 100%;

      .scrollable {
        width: 100%;
      }
    }
  }
}
