@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
@import "../../style/variables.scss";

.dimmedLayer {
  position: fixed;
  z-index: $Z_INDEX_MODAL;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.08);
}

.modal {
  position: fixed;
  z-index: $Z_INDEX_MODAL;
  top: 50%;
  left: 50%;
  width: $WIDTH_MODAL;
  transform: translate(-50%, -50%);
  border-radius: var(--radius-medium);
  background: var(--bg-main);

  > header {
    display: flex;
    justify-content: space-between;
    padding: 20px 24px 12px 32px;
    border: none;
    background: transparent;
    box-shadow: none;

    > .title {
      @include heading2;
      @include one-line-ellipsis;
      color: var(--text-main);
    }
    > .right {
      display: flex;
      gap: 8px;

      span[role="img"] {
        color: var(--icon-default);
      }
    }
  }

  > .content {
    display: flex;
    flex-flow: column nowrap;
    padding: 8px 32px;

    > form {
      display: flex;
      flex-flow: column nowrap;
    }
  }

  > footer {
    display: flex;
    justify-content: space-between;
    padding: 24px 32px;
    background: transparent;
    gap: 8px;

    > .left {
      display: flex;
      gap: 10px;
    }

    > .right {
      display: flex;
      gap: 8px;
    }
  }
}

.modal.small {
  width: $WIDTH_MODAL_SMALL;

  > header {
    padding: 16px 24px 16px 32px;

    > .title {
      @include heading3;
      @include one-line-ellipsis;
    }
  }
}