
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .giveCreditModal {
  section {
    display: flex;
    gap: 24px;

    .vertical {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;

      > p {
        @include body2-regular;
        color: var(--text-sub);
      }
    }
    .inputGroup {
      display: flex;

      > :first-child, :first-child > div, :first-child > div > span {
        border-radius: 8px 0 0 8px;
      }
      > :last-child, :last-child > div > span {
        border-left: 0;
        border-radius: 0 8px 8px 0;
      }
    }

    .invoiceNameField {
      width: calc(100% - 80px);
    }
    .inputAction, .inputAction > :global(.ant-select-selector) {
      @include caption1-regular;
      display: flex;
      height: 36px;
      background: var(--comp-container-100);
    }
    label.inputAction {
      align-items: center;
      justify-content: center;
      width: 80px;
      padding: 6px 12px;
      cursor: pointer;
      border: 1px solid var(--border-default);
      border-radius: 0 8px 8px 0;
    }
  }
}
:global(.ant-select-selector) {
  background: var(--comp-container-100);
}