@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";

.profileMenu {
  display: flex;
  flex-flow: column nowrap;
  width: 260px;
  padding: 16px 0;
  border: 1px solid var(--border-divider);
  border-radius: var(--radius-medium);
  background: var(--bg-main);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

  > .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 4px 24px 4px 16px;
    cursor: pointer;

    > .profileContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px !important;
      height: 48px !important;

      > div,
      :global(.ods-badge),
      :global(.ods-avatar) {
        width: 48px !important;
        height: 48px !important;
      }
      .profile {
        width: 48px !important;
        height: 48px !important;
      }
    }

    > .content {
      display: flex;
      align-items: flex-start;
      flex-flow: column nowrap;
      justify-content: space-between;
      width: 136px;
      height: 44px;
      margin-left: 12px;

      .name {
        @include body1-medium;
        @include one-line-ellipsis;
        width: 100%;
        text-align: left;
        color: var(--text-main);
      }
      .email {
        @include caption1-regular;
        @include one-line-ellipsis;
        width: 100%;
        text-align: left;
        color: var(--text-sub);
      }
    }

    span[role="img"] {
      font-size: var(--icon-medium);
      color: var(--icon-secondary);
    }
    .nextIcon {
      visibility: hidden;
    }
    .nextIcon.visible {
      visibility: visible;
    }
  }
  > .top.hover {
    &:hover {
      border-radius: 8px;
      background: var(--comp-hover-grey);
    }
    .profileContainer {
      .profile {
        cursor: pointer;
      }
    }
  }

  > .divider {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: var(--border-divider);
  }

  .top + .divider {
    margin-top: 8px;
  }

  > .bottom {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    width: 100%;

    > .item {
      @include body2-medium;
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 8px 24px;
      color: var(--text-sub);

      &:disabled {
        color: var(--text-disabled);
      }
    }
  }
}
