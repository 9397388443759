@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
@import "../../style/variables.scss";

.dimmedLayer {
  position: fixed;
  z-index: $Z_INDEX_MODAL;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.08);
}

.confirmModal {
  position: fixed;
  z-index: $Z_INDEX_MODAL;
  top: 50%;
  left: 50%;
  display: flex;
  width: 440px;
  min-height: 148px;
  padding: 20px 32px 24px;
  transform: translate(-50%, -50%);
  border-radius: var(--radius-medium);
  background-color: var(--comp-container-50);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  > .icon {
    display: flex;
    align-items: flex-start;

    span[role="img"] {
      font-size: var(--icon-medium);
      color: var(--semantic-warning);
    }
  }

  > .right {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 336px;
    margin-left: 16px;

    .content {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;

      .title {
        @include body1-semi-bold;
        display: flex;
        width: 100%;
        padding-right: 16px;
        color: var(--text-main);
      }
      .desc {
        @include body2-regular;
        white-space: pre-wrap;
        color: var(--text-sub);

        > strong {
          @include caption1-semi-bold;
        }
        > strong + p {
          @include caption1-regular;
          margin-top: 2px;
        }
      }
    }

    > .footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 24px;
      gap: 8px;

      > .cancelBtn {
        min-width: 80px;
        padding: 6px 16px;
      }

      > .confirmBtn {
        justify-content: center;
        min-width: 84px;
        padding: 6px 16px;
      }
    }
  }

  > .closeBtn {
    position: absolute;
    top: 16px;
    right: 26px;

    span[role="img"] {
      color: var(--icon-secondary);
    }
  }
}
