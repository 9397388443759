@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
@import "../../style/mixin.scss";

.signIn {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  .content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    width: 550px;
    height: fit-content;

    > .title {
      font-size: 48px;
      font-weight: 600;
      font-style: normal;
      line-height: 60px;
      text-align: center;
      white-space: pre-line;
      letter-spacing: -2.4px;
      color: var(--text-main);
    }
    > .desc {
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      line-height: 28px;
      letter-spacing: -0.2px;
      color: var(--text-sub);
    }

    > form {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      padding: 0 35px;

      .forgotPasswordAnchor {
        @include body1-semi-bold;
        display: inline-block;
        align-self: flex-end;
        color: var(--planet-blue-500);
      }

      .navigateToSignUp {
        @include body1-regular;
        text-align: center;
        color: var(--grey-600);

        a {
          @include body1-semi-bold;
          color: var(--planet-blue-500);
        }
      }
      .dividerContainer {
        display: flex;
        align-items: center;
        gap: 12px;

        .divider {
          display: flex;
          width: 100%;
          height: 1px;
          background: var(--border-default);
        }
        > span {
          @include body1-regular;
          white-space: nowrap;
          color: var(--text-sub);
        }
      }
      .signInGoogleBtn {
        @include body1-medium;
        color: var(--text-sub);
      }

      :global(.ods-input-field-wrapper) + :global(.ods-input-field-wrapper) {
        margin-top: 24px;
      }
      :global(.ods-input-field-wrapper) + .forgotPasswordAnchor {
        margin-top: 12px;
      }
      .signInBtn {
        margin: 24px 0;
      }
      .dividerContainer {
        margin: 40px 0;
      }
    }

    .title + .desc {
      margin-top: 16px;
    }
    .desc + form {
      margin-top: 56px;
    }
  }
}

@include mobile-media-query {
  .signIn {
    .content {
      .title {
        font-size: 30px;
        line-height: 40px;
      }

      .desc {
        font-size: 16px;
      }
    }
  }
}

@include tablet-media-query {
  .signIn {
    width: 100%;

    .content {
      width: 100%;
    }
  }
}
