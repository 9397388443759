
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          .userManagement {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 294px); // 왼쪽 영역: 294px
  height: 100%;
  gap: 24px;

  .table {
    width: 100%;
    height: calc(100% - 176px);

    :global(.ant-spin-nested-loading),
    :global(.ant-spin-container) {
      height: 100%;
    }
    :global(.ant-table) {
      height: calc(100% - 12px); // pagination height, margin

      :global(.ant-table-container) {
        height: 100%;
      }
    }
    .loading {
      margin: -20px !important;
    }

    p {
      color: var(--text-main);
    }
    .subText {
      @include caption1-regular;
      color: var(--text-sub);
    }
    .primary {
      @include body2-semi-bold;
      color: var(--comp-primary);
    }

    :global(.ods-tooltip-children-wrapper) {
      @include one-line-ellipsis;
      width: 100%;

      p {
        @include one-line-ellipsis;
      }
    }
    img + :global(.ods-tooltip-children-wrapper) {
      width: calc(100% - 28px - 24px);
      margin-left: 28px;
    }
  }
}

.memo {
  display: flex;
  align-items: center;

  .text {
    margin-right: 2px;
  }

  .icon {
    cursor: pointer;
  }
}
