@import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
@import "../../style/mixin.scss";

.resetPassword {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 14px;

  .content {
    display: flex;
    flex-flow: column nowrap;
    width: 480px;

    > .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 20px;
      white-space: pre-line;
      color: var(--text-main);
    }
    > .desc {
      @include body1-regular;
      margin-bottom: 40px;
      color: var(--text-main);
    }

    form {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      gap: 24px;
    }
  }
}

@include tablet-media-query {
  .resetPassword {
    .content {
      width: 100%;
    }
  }
}
