
              @import "@SIAnalytics/ovision-design-system/build/src/style/Common.scss";
              @import "@ovision-gis-frontend/shared/src/style/mixin.scss";
              @import "src/style/variables.scss";
          :root {
  --chip-red-container: var(--chip-inc-container);

  &[data-theme~="light"] {
    --chip-red-text: var(--red-700);
  }
  &[data-theme~="dark"] {
    --chip-red-text: var(--red-400);
  }
  .red {  //chip
    color: var(--chip-red-text);
    background: var(--chip-red-container);
  }

  --ant-primary-color: var(--comp-primary);
  --ant-primary-color-hover: var(--comp-hover-blue);
  --ant-primary-color-active: var(--comp-primary);
  --ant-primary-color-outline: var(--comp-primary);
  --ant-primary-1: var(--comp-hover-blue);
  --ant-primary-2: var(--comp-hover-blue);
  --ant-primary-3: var(--planet-blue-200);
  --ant-primary-4: var(--planet-blue-300);
  --ant-primary-5: var(--planet-blue-400);
  --ant-primary-6: var(--planet-blue-500);
  --ant-primary-7: var(--planet-blue-600);
  --ant-primary-color-deprecated-pure: var(--planet-blue-50);
  --ant-primary-color-deprecated-l-35: var(--planet-blue-100);
  --ant-primary-color-deprecated-l-20: var(--planet-blue-200);
  --ant-primary-color-deprecated-t-20: var(--planet-blue-300);
  --ant-primary-color-deprecated-t-50: var(--planet-blue-400);
  --ant-primary-color-deprecated-f-12: var(--planet-blue-500);
  --ant-primary-color-active-deprecated-f-30: var(--comp-hover-blue);
  --ant-primary-color-active-deprecated-d-02: var(--comp-hover-blue);
  --ant-success-color: var(--semantic-positive);
  --ant-success-color-hover: var(--green-600);
  --ant-success-color-active: var(--semantic-positive);
  --ant-success-color-outline: var(--semantic-positive);
  --ant-success-color-deprecated-bg: var(--chip-green-container);
  --ant-success-color-deprecated-border: var(--semantic-positive);
  --ant-error-color: var(--semantic-danger);
  --ant-error-color-hover: var(--red-600);
  --ant-error-color-active: var(--semantic-danger);
  --ant-error-color-outline: var(--semantic-danger);
  --ant-error-color-deprecated-bg: var(--chip-red-container);
  --ant-error-color-deprecated-border: var(--semantic-danger);
  --ant-warning-color: var(--semantic-warning);
  --ant-warning-color-hover: var(--moonlight-yellow-600);
  --ant-warning-color-active: var(--semantic-warning);
  --ant-warning-color-outline: var(--semantic-warning);
  --ant-warning-color-deprecated-bg: var(--chip-yellow-container);
  --ant-warning-color-deprecated-border: var(--semantic-warning);
  --ant-info-color: var(--semantic-info);
  --ant-info-color-deprecated-bg: var(--chip-blue-container);
  --ant-info-color-deprecated-border: var(--semantic-info);
}

.ods-input-field-wrapper {
  width: 100%;
}
.ods-text-area-wrapper {
  width: 100%;
}
.ods-radio {
  height: 24px;
}
.ods-text-button {
  border: none;
  background: transparent;
  box-shadow: none;
}
.ods-text-button-secondary:hover {
  background: var(--comp-hover-grey);
}
.ods-icon-button-rectangle,
.ods-icon-button-square {
  color: var(--icon-only);
  border: none;
  border-radius: var(--radius-medium);
  background: transparent;
}
.ods-icon-button-rectangle:hover,
.ods-icon-button-rectangle:focus,
.ods-icon-button-square:hover,
.ods-icon-button-square:focus {
  color: var(--icon-only);
  background: var(--comp-hover-blue);
}

.ant-btn {
  display: inline-flex;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(
    to right bottom,
    rgba(var(--black-color-rgb), 0.7),
    rgba(var(--black-color-rgb), 0.7)
  ) !important;
}
.ant-tooltip-inner {
  background-color: rgba(var(--black-color-rgb), 0.7) !important;
}
.ant-input-affix-wrapper-readonly {
  &:hover,
  &:focus-within {
    border: 1px solid var(--border-default) !important;
    box-shadow: none !important;
  }
  .ant-input-suffix {
    display: none !important;
  }
}

.ant-table {
  background: transparent;
}
.ant-table-body {
  @include custom-scroll-bar;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 12px;
  background: transparent;
}

.ant-table-thead > tr > th {
  @include caption1-medium;
  height: 28px;
  color: var(--text-sub);
}
.ant-table-thead > tr > th::before {
  width: 0 !important;
}

.ant-table-tbody > tr > td {
  @include body2-regular;
  height: 80px;
  color: var(--text-sub);
}
.ant-table-tbody > tr.ant-table-placeholder,
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  height: 160px;
  background: transparent;
}
.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: var(--comp-select);
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--comp-primary);
  background: var(--comp-primary);
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: var(--comp-primary);
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--comp-primary);
}

.ant-table-pagination.ant-pagination {
  margin: 7px 0;
}
.ant-pagination-item {
  @include body2-regular;
  min-width: 20px;
  height: 20px;
  color: var(--text-sub);
}

.ant-pagination-item:hover a {
  color: var(--icon-active);
}
.ant-pagination-item-active,
.ant-pagination-item-active:hover,
.ant-pagination-item-active a,
.ant-pagination-item-active:hover a {
  @include body2-semi-bold;
  line-height: 22px;
  color: var(--icon-active);
  border-color: transparent;
  background: transparent;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-default);
  border: 1px solid var(--border-default);
  border-radius: 6px;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--icon-active);
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--icon-active);
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: var(--icon-disabled);
}

.ant-tabs-tab:hover {
  color: var(--comp-primary);
}
.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid var(--comp-primary);
}
.ant-tabs-tab-btn {
  @include body1-regular;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  @include body1-semi-bold;
  color: var(--comp-primary);
  text-shadow: none;
}
.ant-select .ant-select-selector,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-show-search.ant-select .ant-select-selector {
  height: 100%;
  border: 1px solid var(--border-default);
  border-radius: 8px;
  background: var(--grey-50);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--planet-blue-500);
  box-shadow: 0 0.8px 2.8px 1.5px rgba(74, 125, 255, 0.15);
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  display: flex;
  align-items: center;
}
.ant-select-selection-placeholder {
  color: var(--text-guide);
}
.ant-select-multiple .ant-select-selection-item {
  background: var(--comp-container-100);
}
.ant-select-arrow {
  font-size: 18px;
  color: var(--icon-default);
}
.ant-select-dropdown {
  color: var(--text-main);
  border-radius: 8px;
  background: var(--grey-50);
  box-shadow: 0 4px 8px 0 var(--comp-secondary);
}
.ant-select-item-option:not(.ant-select-item-option-disabled):not(.ant-select-item-option-selected):hover,
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: var(--comp-hover-blue);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: normal;
  background: var(--comp-select);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: var(--comp-primary);
}

.ant-dropdown-menu {
  border-radius: 8px;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background: var(--comp-hover-blue);
}

.ant-picker {
  border-radius: var(--radius-small);
}
.ant-picker-clear .ant-btn,
.ant-input-suffix .ant-btn {
  width: 18px;
  height: 18px;
  padding: 0;
  border: none;
  background: transparent;
}
